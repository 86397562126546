import { createStore as reduxCreateStore } from "redux"

const reducer = (state, action) => {
    // console.log('--> dispatch: ', state, action)

    if (action.type === `SET_USER`) {
        return Object.assign({}, state, {
            userInfo: action.payload,
        })
    }
    if (action.type === `SET_SEARCH`) {
        return Object.assign({}, state, {
            searchTerm: action.payload,
        })
    }
    if (action.type === `SET_SEARCH_RESULT`) {
        return Object.assign({}, state, {
            searchItems: action.payload,
        })
    }
    if (action.type === `SET_LAST_ANCHOR`) {
        return Object.assign({}, state, {
            lastAnchor: action.payload,
        })
    }
    return state
}

const initialState = { 
    userInfo: null,
    searchTerm: null,
    searchItems: [],
    lastAnchor: {pathname: null, accordionIndex: 0}
}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore