// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-change-password-js": () => import("./../../../src/pages/auth/change-password.js" /* webpackChunkName: "component---src-pages-auth-change-password-js" */),
  "component---src-pages-auth-login-js": () => import("./../../../src/pages/auth/login.js" /* webpackChunkName: "component---src-pages-auth-login-js" */),
  "component---src-pages-auth-request-access-js": () => import("./../../../src/pages/auth/request-access.js" /* webpackChunkName: "component---src-pages-auth-request-access-js" */),
  "component---src-pages-auth-reset-password-js": () => import("./../../../src/pages/auth/reset-password.js" /* webpackChunkName: "component---src-pages-auth-reset-password-js" */),
  "component---src-pages-building-js": () => import("./../../../src/pages/building.js" /* webpackChunkName: "component---src-pages-building-js" */),
  "component---src-pages-decor-js": () => import("./../../../src/pages/decor.js" /* webpackChunkName: "component---src-pages-decor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-member-change-password-js": () => import("./../../../src/pages/member/change-password.js" /* webpackChunkName: "component---src-pages-member-change-password-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-signage-js": () => import("./../../../src/pages/signage.js" /* webpackChunkName: "component---src-pages-signage-js" */),
  "component---src-pages-site-js": () => import("./../../../src/pages/site.js" /* webpackChunkName: "component---src-pages-site-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/basicPage.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-building-js": () => import("./../../../src/templates/building.js" /* webpackChunkName: "component---src-templates-building-js" */),
  "component---src-templates-site-js": () => import("./../../../src/templates/site.js" /* webpackChunkName: "component---src-templates-site-js" */)
}

